<template>
  <div class="common-header-container">
        <div class="common-header">
            <div class="top-bar">
                <router-link to="/" class="logo"><img src="../assets/imgs/pc/img_logo@2x.png" alt="香港中醫在綫"></router-link>
                <div class="user-box">
                    <a-popover trigger="click" 
                    :visible="isShowMenu"
                    @visibleChange="handleClickChange">
                        <template slot="content">
                            <div class="user-menu" @click="gotoUserCenter">個人中心</div>
                            <div class="user-menu" @click="preLogout">退出登录</div>
                        </template>
                        <div v-if="userInfo" class="user">
                            <div class="avatar-wrapper">
                                <svg class="avatar" t="1597912182444" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2504" width="48" height="48"><path d="M512 128C406.272 128 320 214.272 320 320s86.272 192 192 192 192-86.272 192-192-86.272-192-192-192z m0 384a320.64 320.64 0 0 0-320 320h64c0-141.76 114.24-256 256-256s256 114.24 256 256h64c0-176.256-143.744-320-320-320z m0-320c71.04 0 128 56.96 128 128 0 71.04-56.96 128-128 128-71.04 0-128-56.96-128-128 0-71.04 56.96-128 128-128z" fill="" p-id="2505"></path></svg>
                                <img v-if="userInfo.photo" :src="userInfo.photo" alt="" class="avatar">
                            </div>
                            <span class="nickname">{{userInfo.clientname || userInfo.clientno}}</span>
                            <a-popconfirm
                                title="確定退出登錄？"
                                ok-text="確定"
                                cancel-text="取消"
                                @visibleChange="onConfirmPopChange"
                                @confirm="logout"
                                :visible="isShowConfirmPop"
                            >
                                <span class="pop-v"></span>
                            </a-popconfirm>
                        </div>
                    </a-popover>
                    <router-link to="/login" class="btn-login" v-if="! userInfo">登錄</router-link>
                    <router-link to="/register" class="btn-register" v-if="! userInfo">註冊</router-link>
                </div>
            </div>
            <ul class="tab-bar">
                <li :class="{'tab-bar-item': true, 'current': currentIndex == 0}"><router-link to="/">首頁</router-link></li>
                <li :class="{'tab-bar-item': true, 'current': currentIndex == 1}"><router-link to="/doctor/list">預約掛號</router-link></li>
                <li :class="{'tab-bar-item': true, 'current': currentIndex == 2}"><router-link to="/ask/list">咨詢醫師</router-link></li>
                <li :class="{'tab-bar-item': true, 'current': currentIndex == 3}"><router-link to="/news/list">健康資訊</router-link></li>
                <li :class="{'tab-bar-item': true, 'current': currentIndex == 4}"><router-link to="/children">兒童健康</router-link></li>
                <li :class="{'tab-bar-item': true, 'current': currentIndex == 5}"><router-link to="/association/list">中醫學會</router-link></li>
                <li :class="{'tab-bar-item': true, 'current': currentIndex == 6}"><router-link to="/job/list">本地中醫藥職位招聘</router-link></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Head',
  data() {
      return {
          isShowConfirmPop: false,
          isShowMenu: false,
          userInfo: this.$store.getters.userInfo
      }
  },
  props: {
    currentIndex: null,
  },
  methods: {
      gotoUserCenter: function(){
          this.isShowMenu = false
          this.$router.push('/user')
      },
      showMenu: function(){
          this.isShowMenu = !this.isShowMenu
      },
      preLogout: function(){
          this.isShowMenu = false
          this.isShowConfirmPop = true
      },
      logout: function(){
          //退出登录
          this.$store.dispatch('logout', this.$store.getters.clientId, this.$store.getters.token)
          this.userInfo = null
          if(this.$route.path.startsWith('/user/')) {
              this.$router.push('/')
          } else {
            window.location.href = '/'
          }
      },
      handleClickChange: function(isShowMenu) {
          this.isShowMenu = isShowMenu
      },
      onConfirmPopChange: function(v){
        this.isShowConfirmPop = false
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    /* 菜单栏 start */
    .common-header-container {
        height: 146px;
        width: 100%;
        background-image: url(../assets/imgs/pc/img_din@2x.png);
        background-size: 50% 100%;
        background-position: right top;
        background-repeat: no-repeat;
        background-color: #fff;
    }

    .common-header {
        width: 1000px;
        height: 146px;
        margin: 0px auto;
    }

    .common-header > .top-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .common-header > .top-bar > .logo {
        display: inline-block;
        margin-top: 30px;
        margin-right: 24px;
    }

    .common-header > .top-bar > .logo > img {
        width: 118px;
        height: 36px;
    }
    .common-header > .top-bar > .user-box {
    position: relative;
        float: right;
    }

    .common-header > .top-bar > .user-box > .btn-login {
        display: inline-block;
        width: 84px;
        height: 32px;
        font-size: 16px;
        font-weight: bold;
        color: #231F20;
        border-radius: 8px;
        line-height: 32px;
        padding-left: 38px;
        text-align: left;
        background-color: #DCF7FB;
        position: relative;
        margin-top: 32px;
        margin-right: 24px;
    }

    .common-header > .top-bar > .user-box > .btn-login::before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 12px;
        top: 8px;
        width: 18px;
        height: 17px;
        background-image: url(../assets/imgs/pc/img_denglu@2x.png);
        background-repeat: no-repeat;
    }

    .common-header > .top-bar > .user-box > .btn-register {
        background-color: #FF8000;
        display: inline-block;
        width: 84px;
        height: 32px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        margin-top: 32px;
    }

    .common-header > .top-bar > .user-box > .user {
        float: right;
        /* margin-right: 118px; */
        height: 32px;
        line-height: 32px;
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        position: relative;
        cursor: pointer;
    }

    .common-header > .top-bar > .user-box > .user .pop-v {
        position: absolute;
        left: 50%;
        top: 16px;
        transform: translateX(-50%);
    }

    .user-menu {
        display: block;
        height: 36px;
        width: 120px;
        line-height: 36px;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        color: #636363;
        border-top: 1px solid #E8E8E8;
        cursor: pointer;
    }

    .user-menu:nth-child(1) {
        border-top-width: 0px;
    }

    .user-menu:hover {
        color: #36C4D0;
        font-weight: bold;
    }

    .avatar-wrapper {
        width: 32px;
        height: 32px;
        position: relative;
    }

    .avatar-wrapper > .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .common-header > .top-bar > .user-box > .user > .nickname {
    margin-left: 8px;
    font-size: 16px;
    color: #231F20;
    font-weight: bold;
    padding-right: 18px;
    position: relative;
    }

    .common-header > .top-bar > .user-box > .user > .nickname > .arrow-down {
    width: 14px;
    height: 7px;
    background-color: transparent;
    position: absolute;
    right: 0px;
    top: 50%;
    overflow: hidden;
    transform: translateY(-50%);
    }

    .common-header > .top-bar > .user-box > .user > .nickname > .arrow-down:after {
    position: absolute;
    content: '';
    right: 0;
    top: -6px;
    width: 10px;
    height: 10px;
    background-color: #043A3D;
    transform: translateX(-2px) rotate(45deg);
    }

    .common-header .tab-bar {
        margin-top: 17px;
    }

    .common-header .tab-bar > .tab-bar-item > a{
        float: left;
        height: 63px;
        margin-right: 48px;
        font-size: 16px;
        color: #636363;
        font-weight: 400;
        line-height: 63px;
    }

    .common-header .tab-bar > .tab-bar-item.current > a,
    .common-header .tab-bar > .tab-bar-item:hover > a {
        font-size: 16px;
        font-weight: bold;
        color: #231F20;
        position: relative;
    }

    .common-header .tab-bar > .tab-bar-item.current > a::before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 14px;
        width: 20px;
        height: 4px;
        background-color: #36C4D0;
    }
   /* 菜单栏 end */

</style>