<template>
    <div class="foot">
        <div class="foot-container">
            <div class="foot-t">
                <div class="fr-links-wrapper">
                    <span class="links-header">友情鏈接：</span>
                    <div class="fr-link-list">
                        <a class="fr-link-item" v-for="item in this.constants.friendlyLinks" :href="item.url" target="_blank" v-text="item.title"></a>
                    </div>
                </div>
                <div class="about-us-wrapper">
                    <span class="about-us-header">關於系統：</span>
                    <div class="about-us-list">
                        <router-link class="about-us-item" to="/home/about">關於我們</router-link>
                        <router-link class="about-us-item feedback" to="/home/feedback">用戶反饋</router-link>
                        <router-link class="about-us-item" to="/home/disclaimer">使用條款及免責聲明</router-link>
                        <router-link class="about-us-item" to="/home/privacy">私隱政策</router-link>
                        <router-link class="about-us-item" to="/home/contact">聯絡我們</router-link>
                        <!-- <router-link class="about-us-item" to="/appPrivacy">大醫隱私政策</router-link> -->
                    </div>
                </div>
                <div class="safe-icos">
                    <a href="javascript:;"
                        data-url="https://seal.godaddy.com/verifySeal?sealID=H1ahlwwmjgaLEmPXgaSipSp5JvRqE5ESwDOgW4qjVZ4kYfENswwF51bdQiuz"
                        class="safe-icon-link"><i class="icon-safe icon1"></i></a>
                    <a href="javascript:;" class="safe-icon-link"><i class="icon-safe icon2"></i></a>
                    <a href="https://www.mcafeesecure.com/verify?host=ectcm.com&popup=1" target="_blank"
                        class="safe-icon-link"><i class="icon-safe icon3"></i></a>
                    <a href="javascript:;" class="safe-icon-link"><i class="icon-safe icon4"></i></a>
                </div>
            </div>
            <div class="foot-b">
                Copyright ©{{ new Date().getFullYear() }} 版權所有 深圳本草通网络有限公司 Shenzhen Bencaotong Network Co., Ltd.
            </div>
        </div>
        <div class="dialog" v-if="isFeedbackDialogShow" @click.self="dismissDialog">
            <div class="dialog-container">
                <div class="title-bar">
                    <span class="title">意見反饋</span>
                    <span class="close-dialog" @click="dismissDialog"></span>
                </div>
                <div class="dialog-c">
                    <div class="fb-connect">
                        <span class="fb-connect-hint">您的聯絡方式：</span>
                        <input type="text" class="fb-connect-v" />
                    </div>
                    <div class="fb-content-hint">輸入您的反饋內容</div>
                    <textarea rows="5" type="text" class="fb-content-v"></textarea>
                </div>
                <div class="fb-about-us">
                    熱綫電話： (852) 3590 4880<br />電郵地址： info@ectcm.com<br />Whatsapp： 93839432<br />地　　址： 香港數碼港道100號數碼港3座F區5樓
                    郵箱210號
                </div>
                <div class="btn-commit-fb">提交反饋</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isFeedbackDialogShow: false
        }
    },
    methods: {
        showFeedbackDialog: function () {
            this.isFeedbackDialogShow = true
            document.body.style = "overflow: hidden;"
        },
        dismissDialog: function () {
            this.isFeedbackDialogShow = false
            document.body.style.overflow = ""
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dialog {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    background-color: rgba(4, 58, 61, 0.3);
    overflow: hidden;
}

.dialog>.dialog-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 936px;
    height: 680px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.dialog>.dialog-container .title-bar {
    display: inline-block;
    height: 46px;
    line-height: 46px;
    background-color: #2B99A6;
    padding-left: 30px;
    font-weight: bold;
    font-size: 20px;
    color: #fff;
}

.dialog>.dialog-container .title-bar .close-dialog {
    float: right;
    width: 46px;
    height: 46px;
    position: relative;
    background-color: #36C4D0;
    cursor: pointer;
}

.dialog>.dialog-container .title-bar .close-dialog::after {
    width: 20px;
    height: 20px;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: url(../assets/imgs/pc/img_cha_white@2x.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.dialog>.dialog-container .fb-connect {
    display: flex;
    flex-direction: row;
    height: 36px;
    line-height: 36px;
    margin-top: 44px;
}

.dialog>.dialog-container .fb-connect .fb-connect-hint {
    font-size: 16px;
    height: 36px;
    line-height: 36px;
    color: #1B2122;
    position: relative;
    margin-left: 36px;
    margin-right: 10px;
}

.dialog>.dialog-container .fb-connect .fb-connect-v {
    height: 36px;
    line-height: 36px;
    font-size: 18px;
    color: #231F20;
    text-align: left;
    padding-left: 17px;
    width: 499px;
    display: inline-block;
    background-color: #E3EFF2;
}

.dialog>.dialog-container .fb-content-hint {
    font-size: 18px;
    height: 18px;
    line-height: 18px;
    color: #1B2122;
    position: relative;
    padding-left: 23px;
    margin-left: 36px;
    margin-top: 40px;
}

.dialog>.dialog-container .fb-content-hint::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 15px;
    height: 15px;
    background-image: url(../assets/imgs/pc/img_fankui@2x.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.dialog>.dialog-container .fb-content-v {
    height: 120px;
    width: 824px;
    background-color: #E3EFF2;
    margin: 10px 36px;
    display: block;
    padding: 16px 20px;
}

.dialog>.dialog-container .fb-about-us {
    font-weight: 400;
    font-size: 14px;
    color: #969696;
    line-height: 20px;
    margin-left: 36px;
}

.dialog>.dialog-container .btn-commit-fb {
    width: 228px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: #2B99A6;
    border-radius: 10px;
    margin: 129px auto 0px auto;
    cursor: pointer;
    user-select: none;
}

.dialog>.dialog-container .btn-commit-fb:active {
    opacity: .7;
}

/* foot start */
.foot-container {
    width: 1000px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
}

.foot-container>.foot-t {
    display: flex;
    flex-direction: row;
}

.foot-container>.foot-t>.fr-links-wrapper {
    display: inline-block;
    width: 449px;
}

.foot-container>.foot-t>.about-us-wrapper {
    display: inline-block;
    width: 326px;
}

.foot-container>.foot-t>.safe-icos {
    flex: 1;
    margin-top: 7px;
}

.foot-container>.foot-t>.safe-icos .safe-icon-link {
    float: left;
    margin-top: 13px;
    height: 32px;
}

.foot-container>.foot-t>.safe-icos .safe-icon-link:nth-child(1) .icon-safe {
    display: inline-block;
    width: 131px;
    height: 32px;
    margin-right: 12px;
    background-image: url(../assets/imgs/pc/img_ver@2x.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.foot-container>.foot-t>.safe-icos .safe-icon-link:nth-child(2) .icon-safe {
    display: inline-block;
    width: 82px;
    height: 32px;
    background-image: url(../assets/imgs/pc/img_peiyu@2x.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.foot-container>.foot-t>.safe-icos .safe-icon-link:nth-child(3) .icon-safe {
    display: inline-block;
    width: 94px;
    height: 32px;
    margin-right: 8px;
    background-image: url(../assets/imgs/pc/img_mc@2x.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.foot-container>.foot-t>.safe-icos .safe-icon-link:nth-child(4) .icon-safe {
    display: inline-block;
    width: 86px;
    height: 32px;
    background-image: url(../assets/imgs/pc/img_sll@2x.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.foot-container>.foot-t>.about-us-wrapper>.about-us-header,
.foot-container>.foot-t>.fr-links-wrapper>.links-header {
    display: inline-block;
    font-size: 14px;
    line-height: 14px;
    color: #969696;
    margin: 22px 0px 16px 0px;
}

.foot-container>.foot-t>.about-us-wrapper>.about-us-list>.about-us-item,
.foot-container>.foot-t>.fr-links-wrapper>.fr-link-list>.fr-link-item {
    color: #636363;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-decoration: underline;
    float: left;
    margin-right: 19px;
    margin-bottom: 19px;
}

.foot-container>.foot-b {
    height: 54px;
    line-height: 54px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #636363;
}

/* foot end */
</style>